<template>
  <BaseImage :src="logo" class="logo-image" :alt="$t(`logo-${host}-alt`)" />
</template>

<script setup lang="ts">
const host = useCurrentHost();
const logo = computed(() => `logo-${host.value}.svg`);
</script>

<style lang="scss" scoped>
.base-logo {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: $light-100;
  font-weight: 600;
  font-size: 1.5rem;
  padding: 14px 0;
}

.logo-image {
  width: 60px;
  height: 60px;
}
</style>
